import React from "react";
import { Router as BlogRouter } from "@reach/router";
import BlogPost from "../modules/blog-post";

const Router = () => {
  return (
    <BlogRouter>
      <BlogPost path={"/blog/:slug"}></BlogPost>
    </BlogRouter>
  );
};

export default Router;
